import React from "react";

function Convenience() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="90"
            fill="none"
            viewBox="0 0 101 90"
        >
            <circle cx="81.5" cy="70.5" r="19.5" fill="#FFF3E8"></circle>
            <circle cx="57.5" cy="50.5" r="30.5" fill="#FFE0D9"></circle>
            <mask
                id="mask0_1_361"
                style={{ maskType: "alpha" }}
                width="90"
                height="90"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#E58D3D" d="M0 0H90V90H0z"></path>
            </mask>
            <g fill="#E58D3D" mask="url(#mask0_1_361)">
                <path d="M64.207 17.844A70.944 70.944 0 0146.002 4.6a1.747 1.747 0 00-2.404 0 70.956 70.956 0 01-18.205 13.243 71.062 71.062 0 01-19.864 6.62c-.87.15-1.48.942-1.401 1.82a74.397 74.397 0 0017.352 41.63A71.392 71.392 0 0044.05 85.28a1.695 1.695 0 001.498 0 71.363 71.363 0 0022.563-17.365 74.4 74.4 0 0017.36-41.63 1.694 1.694 0 00-1.394-1.82 71.047 71.047 0 01-19.87-6.62zm1.342 47.854a67.904 67.904 0 01-20.75 16.16 67.936 67.936 0 01-20.75-16.16A70.769 70.769 0 017.664 27.514 74.379 74.379 0 0044.8 8.17a74.362 74.362 0 0017.876 12.692 74.464 74.464 0 0019.26 6.647A70.779 70.779 0 0165.55 65.697z"></path>
                <path d="M60.051 43.08c-.935 0-1.694.76-1.694 1.695a13.557 13.557 0 11-13.558-13.557 1.695 1.695 0 000-3.39 16.947 16.947 0 1016.947 16.947 1.695 1.695 0 00-1.695-1.695z"></path>
                <path d="M40.914 40.188a1.695 1.695 0 00-2.397 2.396l6.779 6.78c.318.317.749.496 1.198.496h.095a1.695 1.695 0 001.228-.634L61.375 32.28a1.695 1.695 0 10-2.647-2.118L46.356 45.629l-5.442-5.44z"></path>
            </g>
        </svg>
    );
}

export default Convenience;
