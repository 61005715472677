import React, { ReactElement } from "react";
import Repairs from "../icons/repairs";
import PeaceOfMind from "../icons/peaceOfMind";
import OlderHomes from "../icons/olderHomes";
import Convenience from "../icons/convenience";
import CostSavings from "../icons/costSavings";
import HomeValue from "../icons/homeValue";
import styles from "./whyUs.module.scss";
import FeatureList from "@components/shared/featureList";

import colors from "@styles/variables/gethomeprotectionVariables.module.scss";

const whyUsList = [
    {
        title: "Protection From Unexpected Repairs",
        description:
            "With a home warranty, you can avoid paying for any repairs that may arise.",

        icon: <Repairs />,
    },
    {
        title: "Peace of Mind",
        description:
            "Knowing that your home and appliances are covered can give you a sense of calm and security.",

        icon: <PeaceOfMind />,
    },
    {
        title: "An Easy Claims Process",
        description:
            "You only need to make one phone call to your home warranty provider for repairs.",
        icon: <OlderHomes />,
    },
    {
        title: "Convenience",
        description:
            "A home warranty works alongside your standard homeowner's insurance for added convenience.",
        icon: <Convenience />,
    },
    {
        title: "Save Money",
        description:
            "A home warranty saves you money on repairs and replacements.",
        icon: <CostSavings />,
    },
    {
        title: "Increases the Value of Your Home",
        description:
            "Having a home warranty can increase the value of your home.",
        icon: <HomeValue />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Why Should You Get a Home Warranty?"
            classes={{
                mainSectionClasses:
                    "max-w-7xl mx-auto px-5 mt-10 mb-16 lg:mt-[170px] ",
                mainTitleClasses: `text-2xl font-bold lg:text-4xl mb-2 lg:mb-3 mx-auto ${styles["width"]} `,
                mainDescriptionClasses: `text-base font-light mb-0 mx-auto  ${styles["width"]}`,
                innerSectionClasses:
                    "  w-full md:w-7/12 lg:w-full mt-10 md:mx-auto  lg:flex lg:flex-wrap lg:justify-between lg:mt-14 ",
                oneBoxClasses: `  flex flex-col items-center justify-center text-center mb-10 lg:mb-9 lg:w-1/3 ${styles["list-item"]}`,
                iconClasses:
                    "mb-6  children:w-[93%] children:h-[93%]   flex justify-center items-center",
                titleClasses:
                    "text-base lg:text-lg font-normal mb-1 lg:mb-2 leading-6  ",
                descriptionClasses: "text-sm lg:text-base ",
            }}
            colors={{
                mainDescriptionColor: colors.paragraphColor,
                descriptionColor: colors.paragraphColor,
            }}
        />
    );
}
