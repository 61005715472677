import React from "react";

function PeaceOfMind() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="90"
            fill="none"
            viewBox="0 0 101 90"
        >
            <circle cx="81.5" cy="70.5" r="19.5" fill="#FFF3E8"></circle>
            <circle cx="57.5" cy="50.5" r="30.5" fill="#FFE0D9"></circle>
            <mask
                id="mask0_1_359"
                style={{ maskType: "alpha" }}
                width="90"
                height="90"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#E58D3D" d="M0 0H90V90H0z"></path>
            </mask>
            <g fill="#E58D3D" mask="url(#mask0_1_359)">
                <path d="M47.534 77.94h-25.29a5.327 5.327 0 01-5.325-5.324v-6.655a1.331 1.331 0 012.662 0v6.655a2.662 2.662 0 002.662 2.662h25.291a6.155 6.155 0 003.604-1.492l4.773-4.773a1.331 1.331 0 011.883 1.882L53.02 75.67a8.784 8.784 0 01-5.486 2.272z"></path>
                <path d="M71.494 87.258h-37.27a1.331 1.331 0 01-1.332-1.33v-5.325a1.331 1.331 0 012.662 0v3.993h34.44l-3.18-26.642a1.33 1.33 0 01.485-1.19 29.285 29.285 0 10-47.719-22.75v3.993a1.32 1.32 0 01-.175.666l-7.706 13.444a1.33 1.33 0 001.226 1.864h3.993a1.331 1.331 0 011.332 1.33v6.656a1.331 1.331 0 01-2.663 0v-5.324h-2.662a3.994 3.994 0 01-3.573-5.779l7.566-13.21v-3.64A31.945 31.945 0 0141.433 2.938a31.95 31.95 0 0135.924 16.618 31.945 31.945 0 01-7.813 38.803l3.272 27.412a1.331 1.331 0 01-1.321 1.489v-.002z"></path>
                <path d="M33.006 47.44a1.33 1.33 0 01-1.065-.526 21.094 21.094 0 01-4.374-12.9 21.322 21.322 0 0121.298-21.298 21.091 21.091 0 0112.902 4.38 1.33 1.33 0 11-1.617 2.117 18.447 18.447 0 00-11.285-3.835 18.656 18.656 0 00-18.636 18.636A18.453 18.453 0 0034.063 45.3a1.33 1.33 0 01-1.057 2.14zM48.865 55.312a21.087 21.087 0 01-12.902-4.381 1.33 1.33 0 111.616-2.116 18.45 18.45 0 0011.287 3.835A18.656 18.656 0 0067.5 34.014a18.453 18.453 0 00-3.833-11.286 1.331 1.331 0 112.116-1.615 21.096 21.096 0 014.38 12.901 21.322 21.322 0 01-21.299 21.298z"></path>
                <path d="M63.336 36.022a10.41 10.41 0 00-3.931-3.703c.53-1.76.583-3.628.153-5.415a1.33 1.33 0 00-.932-.922 10.093 10.093 0 00-3.206-.259 1.335 1.335 0 00.191 2.662 8.114 8.114 0 011.52.034c.3 1.975-.32 3.98-1.683 5.44a5.882 5.882 0 01-3.255 1.634 8.561 8.561 0 001.997-5.473 9.514 9.514 0 00-4.672-7.816 1.332 1.332 0 00-1.305 0 10.344 10.344 0 00-3.718 3.936 10.356 10.356 0 00-5.399-.154 1.33 1.33 0 00-.924.92 9.504 9.504 0 002.223 8.83 8.552 8.552 0 005.275 2.45 5.87 5.87 0 01-3.46 1.152 6.524 6.524 0 01-5.04-2.662c.133-.17.298-.362.488-.564a1.33 1.33 0 00-1.93-1.832 9.675 9.675 0 00-1.331 1.73 1.334 1.334 0 000 1.307A9.515 9.515 0 0042.21 42a8.953 8.953 0 006.655-3.117A8.953 8.953 0 0055.521 42a9.514 9.514 0 007.816-4.672 1.33 1.33 0 00-.002-1.306zm-14.47-11.044a6.523 6.523 0 012.662 5.042 6.523 6.523 0 01-2.663 5.043 6.511 6.511 0 01-2.662-5.043c.066-2 1.048-3.86 2.662-5.042zm-6.588 8.876a6.514 6.514 0 01-1.686-5.439 7.747 7.747 0 013.062.266 8.28 8.28 0 00-.113 1.34c.006 2 .712 3.935 1.997 5.47a5.875 5.875 0 01-3.26-1.637zm13.243 5.484a5.869 5.869 0 01-3.461-1.152 8.54 8.54 0 005.273-2.451c.316-.32.606-.665.866-1.032a7.701 7.701 0 012.358 1.98 6.521 6.521 0 01-5.036 2.655z"></path>
            </g>
        </svg>
    );
}

export default PeaceOfMind;
